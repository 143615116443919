import service from '@/utils/request'; // @Tags Order
// @Summary 创建Order
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Order true "创建Order"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /order/createOrder [post]

export var createOrder = function createOrder(data) {
  return service({
    url: "/order/createOrder",
    method: 'post',
    data: data
  });
}; // @Tags Order
// @Summary 删除Order
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Order true "删除Order"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /order/deleteOrder [delete]

export var deleteOrder = function deleteOrder(data) {
  return service({
    url: "/order/deleteOrder",
    method: 'delete',
    data: data
  });
}; // @Tags Order
// @Summary 删除Order
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除Order"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /order/deleteOrder [delete]

export var deleteOrderByIds = function deleteOrderByIds(data) {
  return service({
    url: "/order/deleteOrderByIds",
    method: 'delete',
    data: data
  });
}; // @Tags Order
// @Summary 更新Order
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Order true "更新Order"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /order/updateOrder [put]

export var updateOrder = function updateOrder(data) {
  return service({
    url: "/order/updateOrder",
    method: 'put',
    data: data
  });
}; // @Tags Order
// @Summary 用id查询Order
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Order true "用id查询Order"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /order/findOrder [get]

export var findOrder = function findOrder(params) {
  return service({
    url: "/order/findOrder",
    method: 'get',
    params: params
  });
}; // @Tags Order
// @Summary 分页获取Order列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取Order列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /order/getOrderList [get]

export var getOrderList = function getOrderList(params) {
  return service({
    url: "/order/getOrderList",
    method: 'get',
    params: params
  });
};
export var orderSync = function orderSync(data) {
  return service({
    url: "/order/sync",
    method: 'post',
    data: data
  });
};
export var orderRebate = function orderRebate(data) {
  return service({
    url: "/order/rebate",
    method: 'post',
    data: data
  });
}; // @Tags Order
// @Summary 本地生活城市列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "本地生活城市列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /order/getOrderList [get]

export var getLifeCity = function getLifeCity(params) {
  return service({
    url: "/life/city",
    method: 'get',
    params: params
  });
}; // @Tags Order
// @Summary 本地生活门店列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "本地生活门店列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /order/getOrderList [get]

export var getLifeStore = function getLifeStore(params) {
  return service({
    url: "/life/shops",
    method: 'get',
    params: params
  });
}; // @Tags Order
// @Summary 本地生活商品列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "本地生活商品列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /order/getOrderList [get]

export var getLifeGoods = function getLifeGoods(params) {
  return service({
    url: "/life/goods",
    method: 'get',
    params: params
  });
}; // @Tags Order
// @Summary 本地生活商品详情
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "本地生活商品详情"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /order/getOrderList [get]

export var getLifeGoodsDetail = function getLifeGoodsDetail(params) {
  return service({
    url: "/life/goods_detail",
    method: 'get',
    params: params
  });
}; // @Tags Order
// @Summary 本地生活商品编辑
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "本地生活商品编辑"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /order/getOrderList [get]

export var editLifeGoodsDetail = function editLifeGoodsDetail(data) {
  return service({
    url: "/life/update_goods",
    method: 'post',
    data: data
  });
};