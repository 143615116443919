import service from '@/utils/request'; // @Tags SysApi
// @Summary 获取权限客户列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body modelInterface.PageInfo true "获取权限客户列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /agent/list [get]

export var getAgentCustomerList = function getAgentCustomerList(params) {
  return service({
    url: "/agent/list",
    method: 'get',
    params: params
  });
}; // @Tags SysApi
// @Summary 代理商更新
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body modelInterface.PageInfo true "代理商更新"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /agent/update [post]

export var updateAgentCustomer = function updateAgentCustomer(data) {
  return service({
    url: "/agent/update",
    method: 'post',
    data: data
  });
}; // @Tags SysApi
// @Summary 代理商更新
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body modelInterface.PageInfo true "代理商更新"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /agent/update [post]

export var addAgentCustomer = function addAgentCustomer(data) {
  return service({
    url: "/agent/add",
    method: 'post',
    data: data
  });
};