var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-form" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.queryParams }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "伙伴名称：" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.queryParams.name,
                      callback: function($$v) {
                        _vm.$set(_vm.queryParams, "name", $$v)
                      },
                      expression: "queryParams.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号：" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.queryParams.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.queryParams, "phone", $$v)
                      },
                      expression: "queryParams.phone"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "城市" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.queryParams.cityId,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParams, "cityId", $$v)
                        },
                        expression: "queryParams.cityId"
                      }
                    },
                    _vm._l(_vm.cityList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.mername, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.requestData }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-button", { on: { click: _vm.onRest } }, [
                    _vm._v("重置")
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleEdit(null)
                        }
                      }
                    },
                    [_vm._v("添加")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.data, border: "", stripe: "" }
        },
        [
          _c("el-table-column", { attrs: { prop: "id", label: "伙伴ID" } }),
          _c("el-table-column", { attrs: { prop: "name", label: "伙伴名称" } }),
          _c("el-table-column", { attrs: { prop: "phone", label: "手机号" } }),
          _c("el-table-column", {
            attrs: { prop: "cityName", label: "关联城市" }
          }),
          _c("el-table-column", {
            attrs: { prop: "person_to_contact", label: "联系人" }
          }),
          _c("el-table-column", {
            attrs: { prop: "agent_channel", label: "代理渠道" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.agent_channel === "1"
                      ? _c("span", [_vm._v("联盟")])
                      : scope.row.agent_channel === "2"
                      ? _c("span", [_vm._v("SaaS")])
                      : _c("span", [_vm._v("联盟，SaaS")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "created_at", label: "创建时间" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "medium" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.queryParams.page,
          "page-sizes": [20, 50, 100, 200],
          "page-size": _vm.queryParams.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total
        },
        on: {
          "size-change": _vm.requestData,
          "current-change": _vm.requestData,
          "update:currentPage": function($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:current-page": function($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          }
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.newForm.id ? "编辑" : "新增",
            visible: _vm.dialogNewVisible,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogNewVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "newForm",
              attrs: {
                model: _vm.newForm,
                rules: _vm.rules,
                "label-width": "120px",
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "伙伴名称：", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "60%" },
                    attrs: { maxlength: "20" },
                    model: {
                      value: _vm.newForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "name", $$v)
                      },
                      expression: "newForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联城市：", prop: "area" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.newForm.cityId,
                        callback: function($$v) {
                          _vm.$set(_vm.newForm, "cityId", $$v)
                        },
                        expression: "newForm.cityId"
                      }
                    },
                    _vm._l(_vm.cityList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.mername, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "代理渠道：",
                    prop: "agent_channel",
                    required: ""
                  }
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.newForm.agent_channel,
                        callback: function($$v) {
                          _vm.$set(_vm.newForm, "agent_channel", $$v)
                        },
                        expression: "newForm.agent_channel"
                      }
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "1" } }, [
                        _vm._v("联盟")
                      ]),
                      _c("el-checkbox", { attrs: { label: "2" } }, [
                        _vm._v("SaaS")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号：", prop: "phone" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "60%" },
                    attrs: { type: "age", maxlength: "11" },
                    model: {
                      value: _vm.newForm.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "phone", _vm._n($$v))
                      },
                      expression: "newForm.phone"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "密码：",
                    prop: "password",
                    rules: [
                      {
                        required: _vm.newForm.id ? false : true,
                        message: "密码不能为空"
                      }
                    ]
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "60%" },
                    attrs: { placeholder: "新增必填，修改不填则保持原有不变" },
                    model: {
                      value: _vm.newForm.password,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "password", $$v)
                      },
                      expression: "newForm.password"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人：", prop: "person_to_contact" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "60%" },
                    attrs: { maxlength: "10" },
                    model: {
                      value: _vm.newForm.person_to_contact,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "person_to_contact", $$v)
                      },
                      expression: "newForm.person_to_contact"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "代理来源：", prop: "parentId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.newForm.ID && _vm.bindAgent,
                        filterable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.newForm.parentId,
                        callback: function($$v) {
                          _vm.$set(_vm.newForm, "parentId", $$v)
                        },
                        expression: "newForm.parentId"
                      }
                    },
                    _vm._l(_vm.data, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开票资质：", prop: "intelligence" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      model: {
                        value: _vm.newForm.intelligence,
                        callback: function($$v) {
                          _vm.$set(_vm.newForm, "intelligence", $$v)
                        },
                        expression: "newForm.intelligence"
                      }
                    },
                    _vm._l(_vm.intelligenceOptions, function(item) {
                      return _c(
                        "el-radio",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitting },
                  on: {
                    click: function($event) {
                      return _vm.handleSave("newForm")
                    }
                  }
                },
                [_vm._v("确定")]
              ),
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取消")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }